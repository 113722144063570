import * as React from "react"
import './GangSlodziakow.css';
import Creative from "../../components/Creative"
import backgroundWarka from "./images/gangSlodziakow_background.jpg"
import frontBushes from "./images/gangSlodziakow_frontBushes.png"
import stone from "./images/gangSlodziakow_stone.png"
import backBushes from "./images/gangSlodziakow_backBushes.png"
import slodziaki from "./images/slodziaki.png"
import video from "./images/gangSlodziakow_video.mp4"
import SimpleSidebar from "../../components/layout"


const GangSlodziakow = () => (
  <SimpleSidebar>
    <Creative
      className="gangSlodziakow"
      video={video}
      background={backgroundWarka}
      heading="Gang Słodziaków"
      frontImage={stone}
      tags={["drag & drop", "animation"]}  
      tagBackground="#c82e27"
      tagColor="#ffffff"
      headingColor="#ffffff"
      variant="rightTop"
    > 
      <img src={slodziaki} className="gangSlodziakow_image slodziaki"/>
    </Creative>
    <img src={backBushes} className="gangSlodziakow_backBushes"/>
    <img src={frontBushes} className="gangSlodziakow_frontBushes"/>
  </SimpleSidebar>
)

export default GangSlodziakow
